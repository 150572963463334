import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Chakra from '@chakra-ui/react'
import { appConstants } from "./util";
import { ChakraProvider } from '@chakra-ui/react'
import { ErrorBoundary } from 'react-error-boundary'
import * as Icons from '@chakra-ui/icons'
import { Button, IconButton } from '@chakra-ui/react'


//TODO: It's not working even in npm run build , PORT=3001 npx serve -s build
function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
    </div>
  )
}

const ComponentPreview = ({ componentName, components }) => {
  const component = components[componentName];
  const type = Chakra[component.type];
  const { createIcon, ...iconsList } = Icons
  var props = component.props;

  if (!component) return null;

  var children ;
  if (component.type === "Button") {
    if (props.leftIcon) {
      if (Object.keys(iconsList).includes(props.leftIcon)) {
        const Icon = iconsList[props.leftIcon]
        props.leftIcon = <Icon path="" />
      }
    }
  
    if (props.rightIcon) {
      if (Object.keys(iconsList).includes(props.rightIcon)) {
        const Icon = iconsList[props.rightIcon]
        props.rightIcon = <Icon path="" />
      }
    }
    return <Button {...props} />
  } else if (component.type === "IconButton") {
    if (props.icon) {
      if (Object.keys(iconsList).includes(props.icon)) {
        const Icon = iconsList[props.icon]
        props.icon = <Icon path="" />
        return <IconButton {...props} />
      }
    }
  } else if (component.type === "Icon") {
    if (props.icon) {
      if (Object.keys(iconsList).includes(props.icon)) {
        const Icon = iconsList[props.icon]
        return (<Icon {...props} />)
      }
    }
  }
  if (component.children.length > 0) {
    children = React.createElement(
      type,
      component.props,
      component.children.map((key) => (
        <ComponentPreview key={key} componentName={key} components={components} />
      )),
    );
  } else {
    if ((component.type == "Text" || component.type == "Heading" ) && props.children && /<br\s*\/?>/.test(props.children)) {
      const tarray =  props.children.split(/<br\s*\/?>/);
      const tchild = tarray.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index !== tarray.length - 1 && <br />}
        </React.Fragment>
      ));
      children = React.createElement(type, component.props, tchild);
    } else {
      children = React.createElement(type, component.props);
    }
  }
  return (
    children
  );
};

function App() {
  const componentx = {"root":{"id":"root","parent":"root","type":"Box","children":["comp-M06BHRLRM7V6F"],"props":{}},"comp-M06BHRLRM7V6F":{"id":"comp-M06BHRLRM7V6F","props":{"p":1,"width":"","height":"100vh","bgGradient":"linear(to bottom right, #fef1e5,white)"},"children":["comp-M06BKXGU7O97T"],"type":"Box","parent":"root","rootParentType":"Box"},"comp-M06BJV3YRPA0U":{"id":"comp-M06BJV3YRPA0U","props":{"size":"lg","color":"blue","thickness":"4px"},"children":[],"type":"Spinner","parent":"comp-M06BKXGU7O97T","rootParentType":"Spinner"},"comp-M06BK38ZRA0MN":{"id":"comp-M06BK38ZRA0MN","props":{"children":"Building Your Website","ml":"8","size":"xl"},"children":[],"type":"Heading","parent":"comp-M06BKXGU7O97T","rootParentType":"Heading"},"comp-M06BKXGU7O97T":{"id":"comp-M06BKXGU7O97T","props":{"height":"50%"},"children":["comp-M06BJV3YRPA0U","comp-M06BK38ZRA0MN"],"type":"Center","parent":"comp-M06BHRLRM7V6F","rootParentType":"Center"}}
  const [userCode, setuserCode] = useState(componentx);

  useEffect(() => {
    async function fetchdata() {
      try {
        const loc = window.location.host.split(".")
        if (loc.length > 2) {
          const codeid = loc[0];
          const response = await axios.post(`${appConstants.ServerIP}/getpubcode`, {
            pubid: codeid
          });
          setuserCode(response.data);
        }
      } catch (error) {
        console.error('Error fetching and parsing data', error);
      }
    }
    fetchdata();
  }, []);

  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <ChakraProvider resetCSS>
      <div>
        {userCode && userCode.root ? (
          <ComponentPreview key={"root"} componentName={"root"} components={userCode} />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              flexDirection: 'column',
              textAlign: 'center',
              padding: '20px',
            }}
          >
            <h1 style={{ fontSize: '48px', color: '#333' }}>404</h1>
            <p style={{ fontSize: '24px', color: '#666' }}>Page Not Found</p>
            <p style={{ fontSize: '16px', color: '#888' }}>
              Sorry, the page you're looking for doesn't exist.
            </p>
          </div>
        )}
      </div>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default App;
